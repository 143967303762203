import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  Avatar,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { getGames } from "../../store/actions";
import { State } from "../../store/state";
import { useParams, useHistory, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../../consts";
import LazyLoad from "react-lazyload";

export default function Games(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: "1.5rem",
    },
    list: {
      width: "100%",
    },
    listItem: {
      width: "100%",
    },
    progress: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(true);
  const [games, setGames] = useState([]);
  const [showJoinGame, setShowJoinGame] = useState(false);
  const [gameID, setGameID] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      setBusy(true);
      getGames(getAccessTokenSilently, dispatch)
        .then((getGamesResult) => {
          setGames(getGamesResult);
          if (getGamesResult.length == 0) {
            setShowJoinGame(true);
          }
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting your games."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading]);

  const renderJoin = () => {
    return (
      <Grid item container xs={12} className={classes.section}>
        <Grid item xs={12} className={classes.section}>
          <TextField
            id="gameIDInput"
            fullWidth={true}
            required={true}
            label="Game ID"
            value={gameID}
            onChange={(event) => {
              setGameID(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && gameID) {
                event.preventDefault();
                history.push(`${consts.PANEL_JOIN}/${gameID}`);
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} className={classes.section}>
          <Button
            className={classes.button}
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              if (gameID) {
                history.push(`${consts.PANEL_JOIN}/${gameID}`);
              }
            }}
          >
            Join
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderGames = () => {
    return (
      <List className={classes.list}>
        {games.map((game) => {
          return (
            <LazyLoad once key={game.id}>
              <Link
                component={NavLink}
                to={`${consts.PANEL_GAME}/${game.id}`}
                activeClassName={classes.selected}
                color="inherit"
              >
                <ListItem
                  disableGutters={true}
                  classes={{ container: classes.listItem }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt=""
                      src={`${consts.BLOB_URL}${game.id}/banner_thumbnail.jpg`.toLowerCase()}
                      imgProps={{ width: "40", height: "40" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{game.title}</Typography>
                    }
                  />
                </ListItem>
              </Link>
              <Divider variant="middle" component="li" />
            </LazyLoad>
          );
        })}
      </List>
    );
  };

  const renderError = () => {
    return (
      <Typography variant="body1" color="secondary">
        {error}
      </Typography>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          Games
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        {busy && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}
        {error ? renderError() : renderGames()}
      </Grid>
      {showJoinGame ? (
        renderJoin()
      ) : (
        <Grid item container xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={true}
            onClick={() => {
              setShowJoinGame(!showJoinGame);
            }}
            startIcon={<AddIcon />}
          >
            Join Game
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
