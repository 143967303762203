import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardHeader,
  IconButton,
  CardActions,
  Collapse,
  CardContent,
  Badge,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import {
  Favorite as ApproveIcon,
  Favorite as RejectIcon,
  Chat as ExpandIcon,
} from "@material-ui/icons";
import { getPendingPhotos, approvePhoto } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";

export default function Approval(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    pendingPhotoWrapper: {
      margin: "auto",
    },
    actionsWrapper: {
      margin: "auto",
    },
    messagesWrapper: {
      margin: "auto",
    },
    pendingPhoto: {
      [theme.breakpoints.down("xs")]: {
        padding: "8px 0 !important",
      },
    },
    action: {
      marginTop: "0px",
      marginRight: "0px",
    },
    submit: {},
    image: {
      width: "100%",
    },
    helperText: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [pendingPhotos, setPendingPhotos] = useState([]);
  const [busy, setBusy] = useState(true);
  const [message, setMessage] = useState("");
  const [expandedPendingPhoto, setExpandedPendingPhoto] = useState(null);
  const [actionBusy, setActionBusy] = useState(false);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getPendingPhotos(state.game.id, getAccessTokenSilently, dispatch)
        .then((getPendingPhotosResult) => {
          setPendingPhotos(getPendingPhotosResult);
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the feed."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  const renderActions = () => {
    return (
      <Collapse
        in={expandedPendingPhoto ? true : false}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          {actionBusy && (
            <LinearProgress color="secondary" className={classes.progress} />
          )}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            className={classes.actionsWrapper}
          >
            <Grid item xs={12}>
              <TextField
                id="messageInput"
                value={message}
                helperText={`${message.length}/500`}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                disabled={actionBusy}
                multiline={true}
                rows={4}
                fullWidth={true}
                label="Message"
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length <= 500) {
                    setMessage(event.target.value);
                  }
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setActionBusy(true);
                  approvePhoto(
                    state.game.id,
                    expandedPendingPhoto.teamID,
                    expandedPendingPhoto.clueID,
                    "Approved",
                    message,
                    getAccessTokenSilently,
                    dispatch
                  ).then((pendingPhoto) => {
                    let updatedPendingPhotos = pendingPhotos.slice();
                    let pendingPhotoIndex = 0;
                    while (pendingPhotoIndex < updatedPendingPhotos.length) {
                      if (
                        updatedPendingPhotos[pendingPhotoIndex].teamID ==
                          pendingPhoto.teamID &&
                        updatedPendingPhotos[pendingPhotoIndex].clueID ==
                          pendingPhoto.clueID
                      ) {
                        updatedPendingPhotos.splice(pendingPhotoIndex, 1);
                      } else {
                        ++pendingPhotoIndex;
                      }
                    }
                    setPendingPhotos(updatedPendingPhotos);
                    setMessage("");
                    setActionBusy(false);
                  });
                }}
                variant="outlined"
                color="primary"
                disabled={actionBusy || message.length > 500}
                fullWidth={true}
                className={classes.submit}
              >
                <Typography variant="h6">Approve</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setActionBusy(true);
                  approvePhoto(
                    state.game.id,
                    expandedPendingPhoto.teamID,
                    expandedPendingPhoto.clueID,
                    "Rejected",
                    message,
                    getAccessTokenSilently,
                    dispatch
                  ).then((pendingPhoto) => {
                    let updatedPendingPhotos = pendingPhotos.slice();
                    let pendingPhotoIndex = 0;
                    while (pendingPhotoIndex < updatedPendingPhotos.length) {
                      if (
                        updatedPendingPhotos[pendingPhotoIndex].teamID ==
                          pendingPhoto.teamID &&
                        updatedPendingPhotos[pendingPhotoIndex].clueID ==
                          pendingPhoto.clueID
                      ) {
                        updatedPendingPhotos.splice(pendingPhotoIndex, 1);
                      } else {
                        ++pendingPhotoIndex;
                      }
                    }
                    setPendingPhotos(updatedPendingPhotos);
                    setMessage("");
                    setActionBusy(false);
                  });
                }}
                variant="outlined"
                color="primary"
                disabled={
                  actionBusy || message.length < 5 || message.length > 500
                }
                fullWidth={true}
                className={classes.submit}
              >
                <Typography variant="h6">Reject</Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    );
  };

  const renderPendingPhoto = (pendingPhoto) => {
    return (
      <Grid
        key={pendingPhoto.clueID + pendingPhoto.teamID}
        item
        xs={12}
        sm={4}
        lg={3}
        className={classes.pendingPhoto}
      >
        <LazyLoad height="468" offset={100}>
          <Card
            raised={true}
            id={`${pendingPhoto.clueID}-${pendingPhoto.teamID}`}
            className={classes.card}
          >
            <CardHeader classes={{ action: classes.action }} />
            <LazyLoad once>
              <picture>
                <source
                  srcSet={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${pendingPhoto.clueID}-${pendingPhoto.teamID}.webp`.toLowerCase()}
                  type="image/webp"
                />
                <img
                  src={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${pendingPhoto.clueID}-${pendingPhoto.teamID}.jpg`.toLowerCase()}
                  className={classes.image}
                />
              </picture>
            </LazyLoad>
            <CardActions disableSpacing>
              <IconButton
                aria-label="open actions"
                onClick={() => {
                  if (expandedPendingPhoto) {
                    setExpandedPendingPhoto(null);
                  } else {
                    setExpandedPendingPhoto(pendingPhoto);
                  }
                }}
              >
                <ExpandIcon />
              </IconButton>
            </CardActions>
            {expandedPendingPhoto && renderActions()}
          </Card>
        </LazyLoad>
      </Grid>
    );
  };

  const renderPendingPhotos = () => {
    return (
      <Grid
        item
        container
        xs={12}
        spacing={2}
        className={classes.pendingPhotoWrapper}
      >
        {pendingPhotos.length > 0 ? (
          pendingPhotos.map((pendingPhoto) => {
            if (
              !expandedPendingPhoto ||
              (expandedPendingPhoto.clueID == pendingPhoto.clueID &&
                expandedPendingPhoto.teamID == pendingPhoto.teamID)
            ) {
              return renderPendingPhoto(pendingPhoto);
            } else {
              return null;
            }
          })
        ) : (
          <Typography variant="body1">No Pending Photos</Typography>
        )}
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderPendingPhotos()}
    </Grid>
  );
}
