import * as consts from "../consts";
import axios from "axios";

export function setBusy(busy, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_BUSY,
      payload: busy,
    });
    resolve();
  });
}

export function setDarkMode(darkMode, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_DARK_MODE,
      payload: darkMode,
    });
    resolve();
  });
}

export function setAlert(alert, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_ALERT,
      payload: alert,
    });
    resolve();
  });
}

export function getResource(path, dispatch) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${path}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-Play:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve resource ${path}`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getHTTPHeaders(getAccessTokenSilently) {
  return new Promise((resolve) => {
    if (getAccessTokenSilently) {
      getAccessTokenSilently()
        .then((token) => {
          let options = token
            ? { headers: { Authorization: `Bearer ${token}` } }
            : {};
          resolve(options);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      resolve({});
    }
  });
}

export function getSettings(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/settings`, options)
        .then((response) => {
          let settings = response.data;
          dispatch({
            type: consts.ACTION_SET_SETTINGS,
            payload: settings,
          });
          resolve(settings);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve your settings`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function saveSettings(settings, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    registerSubscription(settings.appNotifications).then((subscription) => {
      let data = {
        appNotifications: settings.appNotifications,
        emailNotifications: settings.emailNotifications,
        subscription: JSON.stringify(subscription),
      };
      getHTTPHeaders(getAccessTokenSilently).then((options) => {
        axios
          .put(`${consts.SERVICE_URL}/settings`, data, options)
          .then((response) => {
            let settings = response.data;
            dispatch({
              type: consts.ACTION_SET_SETTINGS,
              payload: settings,
            });
            dispatch({
              type: consts.ACTION_SET_ALERT,
              payload: {
                message: `Successfully saved settings`,
                type: consts.ALERT_TYPE_SUCCESS,
              },
            });
            resolve(settings);
          })
          .catch((err) => {
            if (process.env.NODE_ENV === "development") {
              console.log("CSV2-Play:", err);
            }
            dispatch({
              type: consts.ACTION_SET_ALERT,
              payload: {
                message: `Unable to save settings`,
                type: consts.ALERT_TYPE_ERROR,
              },
            });
            reject(err);
          });
      });
    });
  });
}

export function resendEmailVerification(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {};
      axios
        .post(`${consts.SERVICE_URL}/settings/email/revalidate`, data, options)
        .then((response) => {
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Successfully reset email verification`,
              type: consts.ALERT_TYPE_SUCCESS,
            },
          });
          resolve();
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to resend email verification`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function updateEmailAddress(email, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      let data = {
        email: email,
      };
      axios
        .post(`${consts.SERVICE_URL}/settings/email/`, data, options)
        .then((response) => {
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Successfully changed email`,
              type: consts.ALERT_TYPE_SUCCESS,
            },
          });
          resolve();
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to change email`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getGames(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/manage/games`, options)
        .then((response) => {
          let games = response.data;
          resolve(games);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve your games`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getGame(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/manage/games/${gameID}`, options)
        .then((response) => {
          let game = response.data;
          dispatch({
            type: consts.ACTION_SET_GAME,
            payload: game,
          });
          resolve(game);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve game ${gameID}`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getFeed(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    let options = {};
    axios
      .get(`${consts.BLOB_URL}${gameID}/feed.json`.toLowerCase(), options)
      .then((response) => {
        let feed = response.data;
        resolve(feed);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log("CSV2-Play:", err);
        }
        dispatch({
          type: consts.ACTION_SET_ALERT,
          payload: {
            message: `Unable to retrieve game activities`,
            type: consts.ALERT_TYPE_ERROR,
          },
        });
        reject(err);
      });
  });
}

export function getPendingPhotos(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/manage/games/${gameID}/clues/pending`,
          options
        )
        .then((response) => {
          let pendingPhotos = response.data;
          resolve(pendingPhotos);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve game activities`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getComments(
  gameID,
  teamID,
  clueID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/comments`,
          options
        )
        .then((response) => {
          let comments = response.data;
          resolve(comments);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve clue comments`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function addComment(
  gameID,
  teamID,
  clueID,
  message,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = { message: message };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/games/${gameID}/teams/${teamID}/clues/${clueID}/comments`,
          data,
          options
        )
        .then((response) => {
          let comment = response.data;
          resolve(comment);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve clue comments`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function approvePhoto(
  gameID,
  teamID,
  clueID,
  status,
  message,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = { message: message, status: status };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/manage/games/${gameID}/teams/${teamID}/clues/${clueID}`,
          data,
          options
        )
        .then((response) => {
          let comment = response.data;
          resolve(comment);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve clue comments`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getLeaderboard(gameID, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/games/${gameID}/leaderboard`, options)
        .then((response) => {
          let leaderboard = response.data;
          resolve(leaderboard);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve leaderboard`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function getNotifications(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/notifications`, options)
        .then((response) => {
          let notifications = response.data;
          dispatch({
            type: consts.ACTION_SET_NOTIFICATIONS,
            payload: notifications,
          });
          resolve(notifications);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err);
          }
          dispatch({
            type: consts.ACTION_SET_ALERT,
            payload: {
              message: `Unable to retrieve your notifications`,
              type: consts.ALERT_TYPE_ERROR,
            },
          });
          reject(err);
        });
    });
  });
}

export function removeNotification(
  notificationID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/notifications/${notificationID}`,
          options
        )
        .then((response) => {
          let notification = response.data;
          dispatch({
            type: consts.ACTION_REMOVE_NOTIFICATION,
            payload: notification,
          });
          resolve(notification);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") {
            console.log("CSV2-Play:", err.response);
          }
          reject(err.response.data);
        });
    });
  });
}

export function canUseWebP() {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      let result = image.width > 0 && image.height > 0;
      resolve(result);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src =
      "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
  });
}

export function registerSubscription(appNotifications) {
  return new Promise((resolve, reject) => {
    if (
      appNotifications &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      navigator.serviceWorker.ready.then((serviceWorker) => {
        serviceWorker.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              consts.VAPID_PUBLIC_KEY
            ),
          })
          .then((subscription) => {
            resolve(subscription);
          });
      });
    } else {
      resolve({});
    }
  });
}
export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
