import React, { useContext, useState, useEffect, useRef } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Switch,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
  LinearProgress,
  Button,
  Link,
  TextField,
} from "@material-ui/core";
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import {
  setDarkMode,
  saveSettings,
  resendEmailVerification,
  updateEmailAddress,
} from "../../store/actions";
import Login from "../controls/login";
import * as consts from "../../consts";

export default function Settings(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: "1.5rem",
    },
    paper: {
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [busy, setBusy] = useState(false);

  const emailIssues = () => {
    if (!email || email.length == 0) {
      return "Email is required";
    } else if (!consts.VALIDATION_EMAIL.test(email)) {
      return "Not a valid email address";
    } else {
      return null;
    }
  };

  const submitEmailAddress = () => {
    setInvalidEmail("");
    let emailError = emailIssues();
    if (emailError) {
      setInvalidEmail(emailError);
    } else {
      setBusy(true);
      updateEmailAddress(email, getAccessTokenSilently, dispatch)
        .then(() => {
          setBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue saving your email."
          );
          setBusy(false);
        });
    }
  };

  const renderSettings = () => {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={state.darkMode}
              onChange={(event) => {
                setDarkMode(event.target.checked, dispatch);
              }}
            />
          }
          label="Dark Mode"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.settings.emailNotifications}
              onChange={(event) => {
                saveSettings(
                  {
                    ...state.settings,
                    emailNotifications: event.target.checked,
                  },
                  getAccessTokenSilently,
                  dispatch
                );
              }}
            />
          }
          label="Email Notifications"
        />
        {"serviceWorker" in navigator && "PushManager" in window && (
          <FormControlLabel
            control={
              <Switch
                checked={state.settings.appNotifications}
                onChange={(event) => {
                  saveSettings(
                    {
                      ...state.settings,
                      appNotifications: event.target.checked,
                    },
                    getAccessTokenSilently,
                    dispatch
                  );
                }}
              />
            }
            label="App Notifications"
          />
        )}
        <Button
          onClick={() => {
            resendEmailVerification(getAccessTokenSilently, dispatch);
          }}
        >
          Resend
        </Button>

        <TextField
          id="emailInput"
          required={true}
          error={invalidEmail ? true : false}
          fullWidth={true}
          helperText={invalidEmail}
          label="Email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              submitEmailAddress();
            }
          }}
          variant="outlined"
        />
        <Button
          onClick={() => {
            submitEmailAddress();
          }}
        >
          Update
        </Button>

        <Link
          component={NavLink}
          to={`${consts.PANEL_TERMS}`}
          className={classes.link}
        >
          <Typography variant="body1">Terms</Typography>
        </Link>
        <Link
          component={NavLink}
          to={`${consts.PANEL_PRIVACY}`}
          className={classes.link}
        >
          <Typography variant="body1">Privacy</Typography>
        </Link>
        <Link
          component={NavLink}
          to={`${consts.PANEL_COOKIES}`}
          className={classes.link}
        >
          <Typography variant="body1">Cookies</Typography>
        </Link>
      </FormGroup>
    );
  };

  const renderError = () => {
    return (
      <Typography variant="body1" color="secondary">
        {error}
      </Typography>
    );
  };

  //The user needs to be able to change their email
  //The user needs to be able to resend the verify email
  //The user needs to be able to contact support
  //The user needs to be able to delete their account?
  return (
    <Grid item container xs={12} className={classes.section}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          Settings
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        {busy && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}
        {error ? renderError() : isAuthenticated ? renderSettings() : <Login />}
      </Grid>
    </Grid>
  );
}
