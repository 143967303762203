import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  Typography,
  Link,
  Drawer,
  List,
  ListItem,
  Divider,
  Toolbar,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  SportsEsports as GameIcon,
  Info as AboutIcon,
  LiveHelp as FAQIcon,
  Lock as PrivacyIcon,
  Settings as SettingsIcon,
  ImageSearch as CluesIcon,
  People as TeamIcon,
  NotificationImportant as FeedIcon,
  ThumbsUpDown as VotesIcon,
  Gavel as RulesIcon,
  Poll as ScoreIcon,
  LocalAtm as SponsorsIcon,
  EmojiEvents as PrizesIcon,
  Description as TermsIcon,
  VpnKey as LoginIcon,
  ExitToApp as LogoutIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";

import * as consts from "../../consts";

export default function LeftNavigation(props) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: "70vw",
    },
    title: {
      fontSize: "2rem",
    },
    link: {
      margin: "0px",
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      fontSize: "1rem",
      color: theme.palette.icons.default,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    home: {
      color: theme.palette.icons.home,
    },
    about: {
      color: theme.palette.icons.about,
    },
    faq: {
      color: theme.palette.icons.faq,
    },
    privacy: {
      color: theme.palette.icons.privacy,
    },
    terms: {
      color: theme.palette.icons.terms,
    },
    settings: {
      color: theme.palette.icons.settings,
    },
    game: {
      color: theme.palette.icons.game,
    },
    clues: {
      color: theme.palette.icons.clues,
    },
    team: {
      color: theme.palette.icons.team,
    },
    feed: {
      color: theme.palette.icons.feed,
    },
    votes: {
      color: theme.palette.icons.votes,
    },
    rules: {
      color: theme.palette.icons.rules,
    },
    score: {
      color: theme.palette.icons.score,
    },
    sponsors: {
      color: theme.palette.icons.sponsors,
    },
    prizes: {
      color: theme.palette.icons.prizes,
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { loading, isAuthenticated, logout, loginWithRedirect } = useAuth0();

  const renderLogin = () => {
    if (loading) {
      return null;
    } else {
      if (isAuthenticated) {
        return (
          <Link
            href="#"
            className={classes.link}
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            <LogoutIcon fontSize="small" className={classes.icon} />
            <span>Logout</span>
          </Link>
        );
      } else {
        return (
          <Link
            href="#"
            className={classes.link}
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              });
            }}
          >
            <LoginIcon fontSize="small" className={classes.icon} />
            <span>Login</span>
          </Link>
        );
      }
    }
  };

  const renderGameNavigation = () => {
    return (
      <React.Fragment>
        <Divider />
        <List>
          <ListItem button>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${state.game.id}`}
              className={classes.link + " " + classes.game}
              activeClassName={classes.selected}
              onClick={() => {
                props.onSetOpenDrawer(false);
              }}
            >
              <GameIcon fontSize="small" className={classes.icon} />
              <span>{state.game.title}</span>
            </Link>
          </ListItem>
          <ListItem button>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_FEED}`}
              className={classes.link + " " + classes.feed}
              activeClassName={classes.selected}
              onClick={() => {
                props.onSetOpenDrawer(false);
              }}
            >
              <FeedIcon fontSize="small" className={classes.icon} />
              Feed
            </Link>
          </ListItem>
          <ListItem button>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_RULES}`}
              className={classes.link + " " + classes.rules}
              activeClassName={classes.selected}
              onClick={() => {
                props.onSetOpenDrawer(false);
              }}
            >
              <RulesIcon fontSize="small" className={classes.icon} />
              Rules
            </Link>
          </ListItem>
          <ListItem button>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_SCORE}`}
              className={classes.link + " " + classes.score}
              activeClassName={classes.selected}
              onClick={() => {
                props.onSetOpenDrawer(false);
              }}
            >
              <ScoreIcon fontSize="small" className={classes.icon} />
              Score
            </Link>
          </ListItem>
          {state.game.sponsorsUrl && (
            <ListItem button>
              <Link
                component={NavLink}
                to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_SPONSORS}`}
                className={classes.link + " " + classes.sponsors}
                activeClassName={classes.selected}
                onClick={() => {
                  props.onSetOpenDrawer(false);
                }}
              >
                <SponsorsIcon fontSize="small" className={classes.icon} />
                Sponsors
              </Link>
            </ListItem>
          )}
          {state.game.prizesUrl && (
            <ListItem button>
              <Link
                component={NavLink}
                to={`${consts.PANEL_GAME}/${state.game.id}${consts.PANEL_GAME_PRIZES}`}
                className={classes.link + " " + classes.prizes}
                activeClassName={classes.selected}
                onClick={() => {
                  props.onSetOpenDrawer(false);
                }}
              >
                <PrizesIcon fontSize="small" className={classes.icon} />
                Prizes
              </Link>
            </ListItem>
          )}
        </List>
      </React.Fragment>
    );
  };

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <Toolbar>
        <IconButton
          aria-label="close menu"
          onClick={() => {
            props.onSetOpenDrawer(false);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" className={classes.title}>
          Menu
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_MAIN}
            className={classes.link + " " + classes.home}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <HomeIcon fontSize="small" className={classes.icon} />
            <span>Home</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_FAQ}
            className={classes.link + " " + classes.faq}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <FAQIcon fontSize="small" className={classes.icon} />
            <span>FAQ</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_ABOUT}
            className={classes.link + " " + classes.about}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <AboutIcon fontSize="small" className={classes.icon} />
            <span>About</span>
          </Link>
        </ListItem>
        {isAuthenticated && (
          <ListItem button>
            <Link
              component={NavLink}
              to={consts.PANEL_SETTINGS}
              className={classes.link + " " + classes.settings}
              activeClassName={classes.selected}
              onClick={() => {
                props.onSetOpenDrawer(false);
              }}
            >
              <SettingsIcon fontSize="small" className={classes.icon} />
              <span>Settings</span>
            </Link>
          </ListItem>
        )}
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_PRIVACY}
            className={classes.link + " " + classes.privacy}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <PrivacyIcon fontSize="small" className={classes.icon} />
            <span>Privacy</span>
          </Link>
        </ListItem>
        <ListItem button>
          <Link
            component={NavLink}
            to={consts.PANEL_TERMS}
            className={classes.link + " " + classes.terms}
            activeClassName={classes.selected}
            onClick={() => {
              props.onSetOpenDrawer(false);
            }}
          >
            <TermsIcon fontSize="small" className={classes.icon} />
            <span>Terms</span>
          </Link>
        </ListItem>
        <ListItem button>{renderLogin()}</ListItem>
      </List>
      {state.game && state.game.id && renderGameNavigation()}
    </Drawer>
  );
}
