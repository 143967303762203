import React, { useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Badge,
  Typography,
  Link,
} from "@material-ui/core";
import {
  Notifications as NotificationsIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";
import LeftNavigation from "./leftNavigation";
import NotificationsMenu from "./notificationsMenu";

import * as consts from "../../consts";

export default function Header(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
      fontSize: "2rem",
    },
    image: {
      width: "100px",
    },
    link: {
      margin: "0px",
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    selected: {},
    drawer: {
      "& .MuiDrawer-paper": {
        width: "50vw",
      },
    },
    drawerTitle: {
      fontSize: "1.5rem",
      margin: "0px",
      padding: "12px",
    },
    badge: {
      color: "#ffffff",
    },
  }));
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const { state, dispatch } = useContext(State);
  const { loading, isAuthenticated, logout, loginWithRedirect } = useAuth0();

  const renderLogin = () => {
    if (loading) {
      return null;
    } else {
      if (isAuthenticated) {
        return (
          <Link
            href="#"
            className={classes.link}
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            Log Out
          </Link>
        );
      } else {
        return (
          <Link
            href="#"
            className={classes.link}
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              });
            }}
          >
            Log In
          </Link>
        );
      }
    }
  };

  return (
    <AppBar position="fixed" color="inherit" className={classes.root}>
      {openDrawer && (
        <LeftNavigation
          open={openDrawer}
          onSetOpenDrawer={(open) => {
            setOpenDrawer(open);
          }}
        />
      )}
      {openNotifications && (
        <NotificationsMenu
          open={openNotifications}
          onSetOpenNotifications={(open) => {
            setOpenNotifications(open);
          }}
        />
      )}
      <Toolbar>
        <Hidden mdUp={true}>
          <IconButton
            edge="start"
            aria-label="open left menu"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h1" color="primary" className={classes.title}>
          <Link
            href={consts.SITE_URL + consts.PANEL_EMPTY}
            className={classes.link}
            color="primary"
          >
            Clue Snaps
          </Link>
        </Typography>
        <Hidden smDown={true}>
          <Link
            component={NavLink}
            to={consts.PANEL_MAIN}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Home
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_FAQ}
            className={classes.link}
            activeClassName={classes.selected}
          >
            FAQ
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_ABOUT}
            className={classes.link}
            activeClassName={classes.selected}
          >
            About
          </Link>
          {isAuthenticated && (
            <Link
              component={NavLink}
              to={consts.PANEL_SETTINGS}
              className={classes.link}
              activeClassName={classes.selected}
            >
              Settings
            </Link>
          )}
          <Link
            component={NavLink}
            to={consts.PANEL_PRIVACY}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Privacy
          </Link>
          <Link
            component={NavLink}
            to={consts.PANEL_TERMS}
            className={classes.link}
            activeClassName={classes.selected}
          >
            Terms
          </Link>
          {renderLogin()}
        </Hidden>
        <IconButton
          onClick={() => {
            setOpenNotifications(true);
          }}
          aria-label={`open notifications, you have ${
            state.notifications.length
          } ${
            state.notifications.length == 1 ? "notification" : "notifications"
          }`}
        >
          <Badge
            badgeContent={state.notifications.length}
            color="secondary"
            classes={{ badge: classes.badge }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
