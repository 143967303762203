import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  SportsEsports as GameIcon,
  Camera as JoinIcon,
  Info as AboutIcon,
  LiveHelp as FAQIcon,
  Lock as PrivacyIcon,
  Settings as SettingsIcon,
  EmojiPeople as WelcomeIcon,
  ImageSearch as CluesIcon,
  People as TeamIcon,
  NotificationImportant as FeedIcon,
  ThumbsUpDown as ApprovalIcon,
  Gavel as RulesIcon,
  Poll as ScoreIcon,
  LocalAtm as SponsorsIcon,
  EmojiEvents as PrizesIcon,
  Description as TermsIcon,
  Description as CookiesIcon,
  NavigateNext as BreadcrumbsIcon,
} from "@material-ui/icons";
import { NavLink, useRouteMatch } from "react-router-dom";
import * as consts from "../../consts";
import { State } from "../../store/state";

export default function Breadcrumb(props) {
  const useStyles = makeStyles((theme) => ({
    breadcrumb: { marginBottom: theme.spacing(1) },
    section: { marginBottom: theme.spacing(1) },
    link: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    label: {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    pageTitle: {
      display: "inline",
      fontSize: "1rem",
      fontWeight: "normal",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    home: {
      color: theme.palette.icons.home,
    },
    join: {
      color: theme.palette.icons.join,
    },
    about: {
      color: theme.palette.icons.about,
    },
    faq: {
      color: theme.palette.icons.faq,
    },
    privacy: {
      color: theme.palette.icons.privacy,
    },
    terms: {
      color: theme.palette.icons.terms,
    },
    settings: {
      color: theme.palette.icons.settings,
    },
    game: {
      color: theme.palette.icons.game,
    },
    welcome: {
      color: theme.palette.icons.welcome,
    },
    clues: {
      color: theme.palette.icons.clues,
    },
    team: {
      color: theme.palette.icons.team,
    },
    feed: {
      color: theme.palette.icons.feed,
    },
    votes: {
      color: theme.palette.icons.votes,
    },
    rules: {
      color: theme.palette.icons.rules,
    },
    score: {
      color: theme.palette.icons.score,
    },
    sponsors: {
      color: theme.palette.icons.sponsors,
    },
    prizes: {
      color: theme.palette.icons.prizes,
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  return (
    <Grid item container xs={12} className={classes.section}>
      <Grid item xs={12}>
        <Breadcrumbs
          maxItems={2}
          separator={<BreadcrumbsIcon fontSize="small" />}
          className={classes.breadcrumb}
        >
          {useRouteMatch({
            path: [
              `${consts.PANEL_JOIN}/:gameID/:teamID?/:invitationID?`,
              `${consts.PANEL_SETTINGS}`,
              `${consts.PANEL_ABOUT}`,
              `${consts.PANEL_PRIVACY}`,
              `${consts.PANEL_TERMS}`,
              `${consts.PANEL_FAQ}`,
            ],
          }) && (
            <Link
              component={NavLink}
              to={`${consts.PANEL_MAIN}`}
              className={classes.link + " " + classes.home}
            >
              <HomeIcon className={classes.icon} />
              <span>Home</span>
            </Link>
          )}
          {useRouteMatch({ path: `${consts.PANEL_SETTINGS}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.settings}
            >
              <SettingsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Settings</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_ABOUT}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.about}
            >
              <AboutIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>About</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_PRIVACY}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.privacy}
            >
              <PrivacyIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Privacy</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_TERMS}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.terms}
            >
              <TermsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Terms</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_COOKIES}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.terms}
            >
              <CookiesIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Cookies</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_FAQ}` }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.faq}
            >
              <FAQIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>FAQ</h1>
            </Typography>
          )}
          {useRouteMatch({ path: `${consts.PANEL_GAME}/:gameID` }) && (
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${state.game.id}`}
              className={classes.link + " " + classes.game}
            >
              <GameIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>{state.game.title}</h1>
            </Link>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_RULES}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.rules}
            >
              <RulesIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Rules</h1>
            </Typography>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_FEED}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.feed}
            >
              <FeedIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Feed</h1>
            </Typography>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_APPROVAL}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.approval}
            >
              <ApprovalIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Approval</h1>
            </Typography>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_SCORE}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.score}
            >
              <ScoreIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Score</h1>
            </Typography>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_SPONSORS}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.sponsors}
            >
              <SponsorsIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Sponsors</h1>
            </Typography>
          )}
          {useRouteMatch({
            path: `${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_PRIZES}`,
          }) && (
            <Typography
              component="div"
              className={classes.label + " " + classes.prizes}
            >
              <PrizesIcon className={classes.icon} />
              <h1 className={classes.pageTitle}>Prizes</h1>
            </Typography>
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}
