import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { Route, Switch, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../store/state";
import { setAlert, getNotifications, getSettings } from "../store/actions";
import Header from "./presentational/header";
import Alert from "./presentational/alert";
import Breadcrumb from "./presentational/breadcrumb";
import Main from "./panels/main";
import Settings from "./panels/settings";
import About from "./panels/about";
import Privacy from "./panels/privacy";
import Terms from "./panels/terms";
import Cookies from "./panels/cookies";
import FAQ from "./panels/faq";
import Game from "./panels/game";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../consts";
import { Paper } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const App = () => {
  const { state, dispatch } = useContext(State);
  const theme = createMuiTheme(
    state.darkMode ? consts.THEME_DARK : consts.THEME_LIGHT
  );
  const useStyles = makeStyles((theme) => ({
    main: {
      backgroundColor: state.darkMode ? "#000000" : "#ffffff",
      minHeight: "100vh",
    },
    paper: {
      marginTop: "70px",
      padding: theme.spacing(2),
      minHeight: "100vh",
    },
  }));
  const classes = useStyles();

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getSettings(getAccessTokenSilently, dispatch).then(() => {
        getNotifications(getAccessTokenSilently, dispatch);
      });
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Alert
          alert={state.alert}
          onDismiss={() => {
            setAlert(null, dispatch);
          }}
        />
        <Header />
        <Paper className={classes.paper}>
          <Breadcrumb />
          <Switch>
            <Route
              path={`${consts.PANEL_GAME}/:gameID`}
              render={() => <Game />}
            />
            <Route
              path={`${consts.PANEL_SETTINGS}`}
              render={() => <Settings />}
            />
            <Route path={`${consts.PANEL_ABOUT}`} render={() => <About />} />
            <Route
              path={`${consts.PANEL_PRIVACY}`}
              render={() => <Privacy />}
            />
            <Route
              path={`${consts.PANEL_COOKIES}`}
              render={() => <Cookies />}
            />
            <Route path={`${consts.PANEL_TERMS}`} render={() => <Terms />} />
            <Route path={`${consts.PANEL_FAQ}`} render={() => <FAQ />} />
            <Route render={() => <Main />} />
          </Switch>
        </Paper>
      </div>
    </ThemeProvider>
  );
};
export default App;

//Things to check for
//3) Team name not on black list?
//8) Allow user to change their username/email from My Account
//11) Get all the claims in order
//Look into cookie warning popup
//double check the legal text for anything that seems crazy
