import React, { useContext, useEffect, useState } from "react"; // eslint-disable-line no-unused-vars
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Login from "../controls/login";
import Games from "./games";
import * as consts from "../../consts";

export default function Main(props) {
  const { state, dispatch } = useContext(State);
  const { isAuthenticated } = useAuth0();
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  return (
    <Grid item container xs={12}>
      {isAuthenticated ? (
        <Grid item container xs={12}>
          <Games />
        </Grid>
      ) : (
        <Login />
      )}
    </Grid>
  );
}
