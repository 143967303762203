import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

export default function About(props) {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  return (
    <Grid item container xs={12}>
      <Typography variant="body1">
        Clue Snaps came into being in 2021 out of a little town in British
        Columbia. The founders have been playing some version of this game for
        over a decade now. As avid travelers they collected friends all over the
        world. It was hard to keep in touch and even more difficult to
        coordinate activities that everyone could participate in. People were
        scattered all over the globe. They had jobs and kids that kept them from
        visiting in person. One year we decided to try an experiment. We’d have
        a scavenger hunt but one that you could play regardless of where you
        were on the planet. We put a list of “clues” together and gave everyone
        the whole month of January to collect photos of the clues. The jumbled
        mess of random pics of friends and family that this generated was
        outstanding. I mean some of the submissions might not have exactly made
        any sense in the context of the clue but who cared, they were great. We
        got to connect with people that we hadn’t seen in a long time. The game
        became a bit of a New Year’s tradition. Every January we’d refine the
        game a little bit. Actually, running the game wasn’t that easy. Trying
        to find a platform that would allow everyone to interact but still
        organize the content in a meaningful way turned out to be more difficult
        than you’d expect for such a simple concept. And of course, trying to
        keep track of the score was a nightmare. Even though winning wasn’t the
        point it was important to know when you were in the lead so you could
        properly talk trash. As with most traditions as time went on and
        obligations changed it got more and more difficult to keep the game
        going. A couple years ago we stopped playing. Then the horror that was
        2020 came and someone with some time on their hands decided to learn how
        to make an app. It didn’t take long to realize that our scavenger hunt
        game might be the perfect concept to appify. We basically knew how to do
        it manually and I mean after all what could be better to play during a
        pandemic than a game that was inspired by people separated by thousands
        of miles. Turns out creating an app is pretty difficult so it took a bit
        longer than expected to get it out the door but here it is in all its
        glory, Clue Snaps.
      </Typography>
    </Grid>
  );
}
