import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import {
  useParams,
  useLocation,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import * as consts from "../../consts";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";
import { getGame } from "../../store/actions";
import Login from "../controls/login";
import {
  TextField,
  Grid,
  Button,
  Typography,
  Link,
  Icon,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import {
  ImageSearch as CluesIcon,
  People as TeamIcon,
  NotificationImportant as FeedIcon,
  ThumbsUpDown as ApprovalIcon,
  Gavel as RulesIcon,
  Poll as ScoreIcon,
  LocalAtm as SponsorsIcon,
  EmojiEvents as PrizesIcon,
  Description as TermsIcon,
} from "@material-ui/icons";
import Rules from "./rules";
import Feed from "./feed";
import Score from "./score";
import Sponsors from "./sponsors";
import Prizes from "./prizes";
import Approval from "./approval";
import Busy from "../presentational/busy";

export default function Game(props) {
  const useStyles = makeStyles((theme) => ({
    link: {},
    tile: {
      margin: theme.spacing(1),
      height: "20vh",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    clues: {
      color: theme.palette.icons.clues,
    },
    team: {
      color: theme.palette.icons.team,
    },
    feed: {
      color: theme.palette.icons.feed,
    },
    approval: {
      color: theme.palette.icons.approval,
    },
    rules: {
      color: theme.palette.icons.rules,
    },
    score: {
      color: theme.palette.icons.score,
    },
    sponsors: {
      color: theme.palette.icons.sponsors,
    },
    prizes: {
      color: theme.palette.icons.prizes,
    },
    progress: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { gameID } = useParams();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (
      !isLoading &&
      isAuthenticated &&
      (!state.game || state.game.id != gameID)
    ) {
      setBusy(true);
      getGame(gameID, getAccessTokenSilently, dispatch)
        .then(() => {
          setBusy(false);
        })
        .catch(() => {
          setBusy(false);
        });
    } else {
      setBusy(false);
    }
  }, [isAuthenticated, isLoading]);

  const renderNavigation = () => {
    return (
      <Grid item container xs={12}>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_APPROVAL}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.approval}>
              <ApprovalIcon fontSize="large" />
              <Typography variant="body1">Approval</Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_FEED}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.feed}>
              <FeedIcon fontSize="large" />
              <Typography variant="body1">Feed</Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_RULES}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.rules}>
              <div>
                <RulesIcon fontSize="large" />
                <Typography variant="body1">Rules</Typography>
              </div>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Link
            component={NavLink}
            to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_SCORE}`}
            className={classes.link}
            activeClassName={classes.selected}
          >
            <Paper className={classes.tile + " " + classes.score}>
              <ScoreIcon fontSize="large" />
              <Typography variant="body1">Score</Typography>
            </Paper>
          </Link>
        </Grid>
        {state.game.includeSponsors && (
          <Grid item xs={6} sm={4}>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_SPONSORS}`}
              className={classes.link}
              activeClassName={classes.selected}
            >
              <Paper className={classes.tile + " " + classes.sponsors}>
                <SponsorsIcon fontSize="large" />
                <Typography variant="body1">Sponsors</Typography>
              </Paper>
            </Link>
          </Grid>
        )}
        {state.game.includePrizes && (
          <Grid item xs={6} sm={4}>
            <Link
              component={NavLink}
              to={`${consts.PANEL_GAME}/${gameID}${consts.PANEL_GAME_PRIZES}`}
              className={classes.link}
              activeClassName={classes.selected}
            >
              <Paper className={classes.tile + " " + classes.prizes}>
                <PrizesIcon fontSize="large" />
                <Typography variant="body1">Prizes</Typography>
              </Paper>
            </Link>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPanel = () => {
    return (
      <Switch>
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_FEED}/:clueID?/:teamID?`}
          render={() => <Feed />}
        />
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_APPROVAL}`}
          render={() => <Approval />}
        />
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_RULES}`}
          render={() => <Rules />}
        />
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_SCORE}`}
          render={() => <Score />}
        />
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_SPONSORS}`}
          render={() => <Sponsors />}
        />
        <Route
          path={`${consts.PANEL_GAME}/:gameID${consts.PANEL_GAME_PRIZES}`}
          render={() => <Prizes />}
        />
        <Route render={() => renderNavigation()} />
      </Switch>
    );
  };

  return (
    <Grid item container xs={12}>
      <Busy open={busy} />
      {isAuthenticated ? renderPanel() : <Login />}
    </Grid>
  );
}
