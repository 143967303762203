import React from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Link } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import * as consts from "../../consts";

export default function Login(props) {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading || isAuthenticated) {
    return null;
  } else {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              });
            }}
          >
            Sign In
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
                screen_hint: "signup",
              });
            }}
          >
            Sign up
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Welcome to Clue Snaps. If this is your first time here checkout our{" "}
            <Link
              href={consts.SITE_URL + consts.PANEL_ABOUT}
              className={classes.link}
              color="secondary"
            >
              About Page
            </Link>{" "}
            to learn more about the game. If you already know what you're doing
            sign in to access your games
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
