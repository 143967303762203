import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../store/state";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardHeader,
  IconButton,
  CardActions,
  Collapse,
  CardContent,
  Badge,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import {
  Favorite as HeartIcon,
  Chat as CommentsIcon,
} from "@material-ui/icons";
import { getFeed, getComments, addComment } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import LazyLoad from "react-lazyload";
import * as consts from "../../consts";

export default function Feed(props) {
  const useStyles = makeStyles((theme) => ({
    section: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      width: "100%",
    },
    heart: {
      color: theme.palette.icons.heart,
    },
    badge: {
      color: "#ffffff",
    },
    activityWrapper: {
      margin: "auto",
    },
    commentsWrapper: {
      margin: "auto",
    },
    messagesWrapper: {
      margin: "auto",
    },
    activity: {
      [theme.breakpoints.down("xs")]: {
        padding: "8px 0 !important",
      },
    },
    action: {
      marginTop: "0px",
      marginRight: "0px",
    },
    submit: {},
    image: {
      width: "100%",
    },
    signature: {
      textAlign: "right",
    },
    helperText: {
      textAlign: "right",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState([]);
  const [busy, setBusy] = useState(true);
  const { clueID, teamID } = useParams();
  const [message, setMessage] = useState("");
  const [expandedActivity, setExpandedActivity] = useState(null);
  const [commentsBusy, setCommentsBusy] = useState(true);

  useEffect(() => {
    if (!isLoading && isAuthenticated && state.game && state.game.id) {
      setBusy(true);
      getFeed(state.game.id, getAccessTokenSilently, dispatch)
        .then((getFeedResult) => {
          setActivities(getFeedResult);
          setBusy(false);
          if (clueID && teamID) {
            let element = document.getElementById(`${clueID}-${teamID}`);
            if (element) {
              element.scrollIntoView();
            }
          }
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue getting the feed."
          );
          setBusy(false);
        });
    }
  }, [isAuthenticated, isLoading, state.game]);

  useEffect(() => {
    if (expandedActivity && state.game.commentsEnabled) {
      setCommentsBusy(true);
      getComments(
        state.game.id,
        expandedActivity.teamID,
        expandedActivity.clueID,
        getAccessTokenSilently,
        dispatch
      )
        .then((getCommentsResult) => {
          let updatedActivities = activities.slice();
          let activityIndex = 0;
          while (activityIndex < updatedActivities.length) {
            if (
              updatedActivities[activityIndex].clueID ==
                expandedActivity.clueID &&
              updatedActivities[activityIndex].teamID == expandedActivity.teamID
            ) {
              updatedActivities[activityIndex].comments = getCommentsResult;
            }
            ++activityIndex;
          }
          setMessage("");
          setActivities(updatedActivities);
          setCommentsBusy(false);
        })
        .catch((exception) => {
          setError(
            exception.response && exception.response.data
              ? exception.response.data
              : "Error: There was an issue with this request."
          );
          setCommentsBusy(false);
        });
    }
  }, [expandedActivity]);

  const getDisplayDate = (date) => {
    let displayDate = `posted on ${date.toDateString()}`;
    let seconds = Math.floor((new Date() - date) / 1000);
    let count = 0;
    let interval = seconds;
    if (interval < 5) {
      displayDate = `just posted`;
    } else {
      if (interval >= 1 && interval < 60) {
        count = Math.floor(interval);
        displayDate = `posted ${count} second${count > 1 ? "s" : ""} ago`;
      } else {
        interval = interval / 60;
        if (interval >= 1 && interval < 60) {
          count = Math.floor(interval);
          displayDate = `posted ${count} minute${count > 1 ? "s" : ""} ago`;
        } else {
          interval = interval / 60;
          if (interval >= 1 && interval < 24) {
            count = Math.floor(interval);
            displayDate = `posted ${count} hour${count > 1 ? "s" : ""} ago`;
          }
        }
      }
    }
    return displayDate;
  };

  const renderComments = () => {
    return (
      <Collapse
        in={expandedActivity ? true : false}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          {commentsBusy && (
            <LinearProgress color="secondary" className={classes.progress} />
          )}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            className={classes.commentsWrapper}
          >
            <Grid
              item
              container
              spacing={2}
              xs={12}
              className={classes.messagesWrapper}
            >
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {expandedActivity.comments &&
                expandedActivity.comments.map((comment) => {
                  return (
                    <React.Fragment key={comment.created}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {comment.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.signature}>
                        <Typography variant="caption">
                          {`-${comment.author} ${getDisplayDate(
                            new Date(comment.created)
                          )}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="commentInput"
                value={message}
                helperText={`${message.length}/500`}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                disabled={commentsBusy}
                multiline={true}
                rows={4}
                fullWidth={true}
                label="Message"
                variant="outlined"
                onChange={(event) => {
                  if (event.target.value.length <= 500) {
                    setMessage(event.target.value);
                  }
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  setCommentsBusy(true);
                  addComment(
                    state.game.id,
                    expandedActivity.teamID,
                    expandedActivity.clueID,
                    message,
                    getAccessTokenSilently,
                    dispatch
                  ).then((comment) => {
                    let updatedActivities = activities.slice();
                    let activityIndex = 0;
                    while (activityIndex < updatedActivities.length) {
                      if (
                        updatedActivities[activityIndex].clueID ==
                          expandedActivity.clueID &&
                        updatedActivities[activityIndex].teamID ==
                          expandedActivity.teamID
                      ) {
                        updatedActivities[activityIndex].comments.push(comment);
                      }
                      ++activityIndex;
                    }
                    setActivities(updatedActivities);
                    setMessage("");
                    setCommentsBusy(false);
                  });
                }}
                variant="outlined"
                color="primary"
                disabled={commentsBusy || message.length > 500}
                fullWidth={true}
                className={classes.submit}
              >
                <Typography variant="h6">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    );
  };

  const renderActivity = (activity) => {
    return (
      <Grid
        key={activity.clueID + activity.teamID}
        item
        xs={12}
        sm={4}
        lg={3}
        className={classes.activity}
      >
        <LazyLoad height="468" offset={100}>
          <Card
            raised={true}
            id={`${activity.clueID}-${activity.teamID}`}
            className={classes.card}
          >
            <CardHeader
              classes={{ action: classes.action }}
              action={
                activity.voteCount > 0 && (
                  <Badge
                    badgeContent={activity.voteCount}
                    color="primary"
                    classes={{ badge: classes.badge }}
                  >
                    <HeartIcon className={classes.heart} />
                  </Badge>
                )
              }
              title={activity.clueTitle}
              subheader={activity.teamTitle}
            />
            <LazyLoad once>
              <picture>
                <source
                  srcSet={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${activity.clueID}-${activity.teamID}.webp`.toLowerCase()}
                  type="image/webp"
                />
                <img
                  src={`${consts.BLOB_URL}${state.game.id}/clues/${state.game.id}-${activity.clueID}-${activity.teamID}.jpg`.toLowerCase()}
                  alt={`${activity.teamTitle} - ${activity.clueTitle}`}
                  className={classes.image}
                />
              </picture>
            </LazyLoad>
            <CardActions disableSpacing>
              {state.game.commentsEnabled && (
                <IconButton
                  aria-label="open comments"
                  onClick={() => {
                    if (expandedActivity) {
                      setExpandedActivity(null);
                    } else {
                      setExpandedActivity(activity);
                    }
                  }}
                >
                  <Badge
                    badgeContent={activity.commentCount}
                    color="primary"
                    classes={{ badge: classes.badge }}
                  >
                    <CommentsIcon />
                  </Badge>
                </IconButton>
              )}
            </CardActions>
            {expandedActivity && state.game.commentsEnabled && renderComments()}
          </Card>
        </LazyLoad>
      </Grid>
    );
  };

  const renderActivities = () => {
    return (
      <Grid
        item
        container
        xs={12}
        spacing={2}
        className={classes.activityWrapper}
      >
        {activities.map((activity) => {
          if (
            !expandedActivity ||
            (expandedActivity.clueID == activity.clueID &&
              expandedActivity.teamID == activity.teamID)
          ) {
            return renderActivity(activity);
          } else {
            return null;
          }
        })}
      </Grid>
    );
  };

  const renderError = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="secondary">
          {error}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} className={classes.section}>
      {busy && (
        <LinearProgress color="secondary" className={classes.progress} />
      )}
      {error ? renderError() : renderActivities()}
    </Grid>
  );
}
